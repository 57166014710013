import { ReactNode, useEffect, useRef, useState } from "react";

type ContextMenuProps = {
  children: ReactNode;
  content: ReactNode;
  className?: string;
};

type points = {
  x: number;
  y: number;
};

export function ContextMenu({
  children,
  content,
  className,
}: ContextMenuProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [points, setPoints] = useState<points>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          setOpen(true);
          setPoints({
            x: e.pageX,
            y: e.pageY,
          });
        }}
      >
        {children}
      </div>
      {open && (
        <div
          ref={ref}
          style={{
            left: points.x,
            top: points.y,
            position: "fixed",
            zIndex: 1000,
          }}
          onClick={() => {
            setOpen(false);
          }}
          onKeyDown={() => {
            setOpen(false);
          }}
          role="presentation"
          className={`rounded-md bg-white p-3 opacity-100 shadow-lg ${className}`}
        >
          {content}
        </div>
      )}
    </div>
  );
}
