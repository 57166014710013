import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "./styles.css";

import { AgGridReact, AgGridReactProps } from "ag-grid-react"; // React Data Grid Component
import { ForwardedRef } from "react";
import { tv, VariantProps } from "tailwind-variants";

/* Typed any because that's the default generic type of AgGridReactProps */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GridProps<TData = any> = AgGridReactProps<TData> &
  VariantProps<typeof gridVariants> & {
    gridRef?: ForwardedRef<AgGridReact>;
  };

const gridVariants = tv({
  base: "ag-theme-quartz",
  variants: {
    enableCellBorders: {
      true: "with-cell-borders",
    },
    enableDarkerHeader: {
      true: "with-darker-header",
    },
  },
});

export function Grid({
  enableCellBorders,
  enableDarkerHeader,
  ...props
}: GridProps) {
  return (
    <div
      data-testid="revv-grid"
      style={{ height: "100%" }}
      className={gridVariants({ enableCellBorders, enableDarkerHeader })} // applying the grid theme
    >
      <AgGridReact {...props} ref={props.gridRef} />
    </div>
  );
}
