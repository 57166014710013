import {
  Button as NextUiButton,
  ButtonProps as NextUiButtonProps,
} from "@nextui-org/react";

export type ButtonProps = NextUiButtonProps;

export function Button(props: ButtonProps) {
  return <NextUiButton {...props} />;
}
