export function isCurrentPage(route: string | RegExp, pathname: string) {
  if (route instanceof RegExp) {
    return route.test(pathname);
  }

  const routeParts = (route || "").split("/");
  const pathnameParts = (pathname || "").split("/");
  let i;

  for (i = 0; i < routeParts.length; i++) {
    const part = routeParts[i];

    if (part === "*") {
      return true;
    }

    if (part?.[0] === "!") {
      if (part.slice(1) === pathnameParts[i]) {
        return false;
      }

      continue;
    }

    if (routeParts[i] !== pathnameParts[i]) {
      return false;
    }
  }

  if (i < pathnameParts.length) {
    return false;
  }

  return true;
}
