import {
  Link as NextUiLink,
  LinkProps as NextUiLinkProps,
} from "@nextui-org/react";

export type LinkProps = NextUiLinkProps & {
  defaultSize?: "sm" | "md" | "lg";
};

export function Link({ defaultSize = "sm", size, ...props }: LinkProps) {
  return <NextUiLink size={size || defaultSize} {...props} />;
}
